import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../AppContext";
import { useSelector } from "react-redux";
import { selectPath } from "../../features/path/pathSlice";
import { animationPresets } from "../../AppContext";
import {
  useGetSubcatsQuery,
  useGetProductsQuery,
} from "../../app/services/products";

import "./brand-list.scss";

const BrandList = () => {
  const path = useSelector(selectPath);
  const { brand: currentBrand } = path;

  const category_url = path.category?.url;

  const { data: products } = useGetProductsQuery(
    { category_url },
    { skip: !category_url }
  );
  const { data: brands } = useGetSubcatsQuery(category_url, {
    skip: !category_url,
  });

  const { setPreset } = useContext(AppContext);
  const onMouseDown = () => {
    setPreset(animationPresets.moveToLeftScaleUp);
  };

  const getGroupId = (brand) => {
    const relatedProduct = products?.find(
      (product) => product?.brand?.toLowerCase() === brand?.toLowerCase()
    );
    return relatedProduct?.group_id;
  };

  const brandsWithGroupId =
    category_url && brands?.filter((brand) => getGroupId(brand));

  return (
    brandsWithGroupId?.length > 1 && (
      <div className="brand-list">
        {brandsWithGroupId.map((brand, i) => {
          const group_id = getGroupId(brand);
          const url = `/katalogas/${category_url}/${group_id}`;

          const safeBrand = brand || path.category?.title || "Kiti";

          const brandName =
            safeBrand.charAt(0).toUpperCase() +
            safeBrand.slice(1).toLowerCase();

          return (
            <div key={i} className="brand-list__item">
              <Link
                title={brandName}
                to={url.toLowerCase()}
                onMouseDown={onMouseDown}
                className={`${
                  currentBrand?.toLowerCase() === brand?.toLowerCase()
                    ? "current"
                    : ""
                }`}
              >
                {brandName}
              </Link>
            </div>
          );
        })}
      </div>
    )
  );
};

export default BrandList;
