//export const baseName = "/amber";
//export const baseUrl = `https://asko.lt${baseName}/`;

export const baseName = "";
export const baseUrl = process.env.REACT_APP_BASE_PATH;
export const baseUrlApi = `https://amberkaledos.asko.lt/`;

export const productImageBaseUrl =
  "https://www.amberdrinks.lt/media/catalog/product";

export const productFullBaseUrl = "https://www.amberdrinks.lt";

export const categoryNews = {
  title: "Naujienos",
  url: "naujienos",
};
