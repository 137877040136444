import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ArrowPrev } from "../Navigation";
import { setCategory } from "../../features/path/pathSlice";
import { ReactComponent as Logo } from "../../assets/2022/logo.svg";
import "./page-contacts.scss";

const PageContacts = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCategory(null));
  }, [dispatch]);

  /*
  const history = useHistory();

  const onPrevClick = () => {
    history.goBack();
  }
  */

  return (
    <div className="page-contacts">
      <div className="page-contacts__inner">
        <div className="page-contacts__header">
          <div className="page-contacts__logo-wrapper">
            <Link to="/katalogas" className="page-contacts__logo">
              <Logo className="page-contacts__logo" />
            </Link>
          </div>
        </div>
        <h1>
          Norėdami užsakyti gėrimus iš šio katalogo, sužinoti daugiau arba gauti
          platesnį pasiūlymą – kreipkitės:
        </h1>
        <div className="page-contacts__list">
          <div>
            <h2>
              Parduotuvė
              <br />
              Vilniuje:
            </h2>
            Metalo g. 2B, Vilnius
            <br />
            tel.: (8-5) 210 62 03
            <br />
            Darbo laikas:
            <br />
            darbo dienomis, 10:00-18:00h
          </div>

          <div>
            <h2>VILNIUS:</h2>
            Osvaldas Paliulis
            <br />
            Vilniaus regiono HRC vadovas
            <br />
            Tel.: +370 5 213 1611
            <br />
            Mob: +370 618 12458
            <br />
            El. paštas:
            <br />
            <a href="mailto:osvaldas.paliulis@amberbev.com">
              osvaldas.paliulis@amberbev.com
            </a>
          </div>

          <div>
            <h2>KAUNAS:</h2>
            Vladimir Malachovič
            <br />
            Tel.: +370 5 213 1611
            <br />
            Mob: +370 620 70974
            <br />
            El. paštas:
            <br />
            <a href="mailto:vladimir.malachovic@amberbev.com">
              vladimir.malachovic@amberbev.com
            </a>
          </div>

          <div>
            <h2>KLAIPĖDA:</h2>
            Evaldas Vaičiulis
            <br />
            Klaipėdos regiono vadovas
            <br />
            Mob.: +370 698 70018
            <br />
            El. paštas:
            <br />
            <a href="mailto:evaldas.vaiciulis@amberbev.com">
              evaldas.vaiciulis@amberbev.com
            </a>
          </div>

          <div>
            <h2>ŠIAULIAI:</h2>
            Miroslavas Makovskis
            <br />
            Šiaulių regiono vadovas
            <br />
            Mob.: +370 698 28508
            <br />
            El. paštas:
            <br />
            <a href="mailto:miroslavas.makovskis@amberbev.com">
              miroslavas.makovskis@amberbev.com
            </a>
          </div>

          <div>
            <h2>PANEVĖŽYS:</h2>
            Aidas Tumelis
            <br />
            Panevėžio regiono vadovas
            <br />
            Mob.: +370 698 70023
            <br />
            El. paštas:
            <br />
            <a href="mailto:aidas.tumelis@amberbev.com">
              aidas.tumelis@amberbev.com
            </a>
          </div>
        </div>
        <div className="page-contacts__info">
          Įmonėms išrašome sąskaitas reprezentacinėms reikmėms.
          <br />
          Užsakymus virš 50 Eur įmonėms pristatome nemokamai (tik Lietuvos
          Respublikos teritorijoje).
          <br />
          Kai kurių prekių kiekis ribotas. Produktų nuotraukos yra informacinio
          pobūdžio ir gali nežymiai skirtis nuo realaus produkto.
        </div>
        <ArrowPrev to="/katalogas" dark />
      </div>
    </div>
  );
};

export default PageContacts;
