import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { animationPresets, AppContext } from "../../AppContext";
import { Link } from "react-router-dom";
import { ArrowNext } from "../Navigation";
import { ReactComponent as Logo } from "../../assets/2022/logo_white.svg";
import katalogas from "../../assets/2022/gerimu_katalogas.png";
import { setCategory } from "../../features/path/pathSlice";
import "./page-cover.scss";

const PageCover = () => {
  const dispatch = useDispatch();
  const { setPreset } = useContext(AppContext);

  const onMouseDown = () => {
    setPreset(animationPresets.moveToLeftScaleUp);
  };

  useEffect(() => {
    dispatch(setCategory(null));
  }, [dispatch]);

  return (
    <Link to="/katalogas" onMouseDown={onMouseDown}>
      <div className="page-cover">
        <div className="page-cover__wo">
          <Logo className="page-cover__logo" />
          <img src={katalogas} className="page-cover__name" alt="" />
        </div>
      </div>
      <ArrowNext to="/katalogas" anchor={false} />
    </Link>
  );
};

export default PageCover;
