import { baseUrlApi } from "../constants";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: `${baseUrlApi}api/` }),
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: () => ({
        url: ``,
        //mode: "no-cors",
        //headers,
      }),
    }),
    getSubcats: builder.query({
      query: (category_url) => `subcats?category=${category_url}`,
    }),
    getProduct: builder.query({
      query: (product_url_key) => `product?product_url_key=${product_url_key}`,
    }),
    getProducts: builder.query({
      query: ({ category_url, subcat }) =>
        `products?category=${category_url}${subcat ? `&subcat=${subcat}` : ""}`,
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useGetSubcatsQuery,
  useGetProductQuery,
  useGetProductsQuery,
} = api;
