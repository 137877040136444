import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import { AppContext } from "./AppContext";
import { PageTransition } from "@steveeeie/react-page-transition";
import PageCategory from "./components/PageCategory";
import PageContacts from "./components/PageContacts";
import PageCover from "./components/PageCover";
import PageContents from "./components/PageContents";
import MenuBottom from "./components/MenuBottom";
import Phone from "./components/Phone";
import BrandList from "./components/BrandList";
import "./app.scss";

function App() {
  const { preset } = useContext(AppContext);

  return (
    <div className="app">
      <Route
        render={({ location }) => {
          return (
            <PageTransition preset={preset} transitionKey={location.pathname}>
              <Switch location={location}>
                <Route exact path="/">
                  <PageCover />
                </Route>
                <Route exact path="/katalogas">
                  <PageContents />
                </Route>
                <Route path="/katalogas/:category_url/:group_id_param?">
                  <PageCategory />
                </Route>
                <Route exact path="/kontaktai">
                  <PageContacts />
                </Route>
              </Switch>
            </PageTransition>
          );
        }}
      />

      <BrandList />
      <MenuBottom />
      <Phone />
    </div>
  );
}

export default App;
