import React, { createContext, useState } from "react";
import { presets } from "@steveeeie/react-page-transition";

export const AppContext = createContext();

export const animationPresets = Object.keys(presets).reduce(
  (acc, key) => ({ ...acc, [key]: key }),
  {}
);

export function AppContextProvider({ children }) {
  const [preset, setPreset] = useState(animationPresets.moveToLeftScaleUp);

  return (
    <AppContext.Provider
      value={{
        preset,
        setPreset,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
