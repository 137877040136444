import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../AppContext";
import { useSelector } from "react-redux";
import { selectPath } from "../../features/path/pathSlice";
import { useGetCategoriesQuery } from "../../app/services/products";
import { animationPresets } from "../../AppContext";

import "./menu-bottom.scss";

const MenuBottom = () => {
  const { data: categories } = useGetCategoriesQuery();

  const path = useSelector(selectPath);
  const { category } = path;

  const { setPreset } = useContext(AppContext);
  const onMouseDown = () => {
    setPreset(animationPresets.moveToLeftScaleUp);
  };

  return (
    category && (
      <nav className="menu-bottom">
        {categories && (
          <div className="menu-bottom__items">
            <div className="menu-bottom__items-fill"></div>
            <ul>
              {[...categories].map((cat, i) => (
                <li
                  key={i}
                  className={`${
                    category?.url.toLowerCase() === cat.url.toLowerCase()
                      ? "current"
                      : ""
                  }`}
                >
                  <Link
                    title={cat.title}
                    to={`/katalogas/${cat.url.toLowerCase()}`}
                    onMouseDown={onMouseDown}
                  >
                    {cat.title.replace(" GĖRIMAI", "")}
                  </Link>
                </li>
              ))}
            </ul>
            <div className="menu-bottom__items-fill"></div>
          </div>
        )}
        <div className="menu-bottom__back"></div>
      </nav>
    )
  );
};

export default MenuBottom;
