import React from "react";
import { ReactComponent as LoaderSVG } from "../../assets/Eclipse-1s-200px.svg";

import "./loader.scss";

const Loader = () => {
  return (
    <div className="loader">
      <LoaderSVG />
    </div>
  );
};

export default Loader;
