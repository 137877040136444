import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../AppContext";
import { useSelector } from "react-redux";
import { selectPath } from "../../features/path/pathSlice";
import { animationPresets } from "../../AppContext";
import { ReactComponent as PhoneIcon } from "../../assets/phone.svg";
import "./phone.scss";

const Phone = () => {
  const path = useSelector(selectPath);
  const { category } = path;

  const { setPreset } = useContext(AppContext);
  const onMouseDown = () => {
    setPreset(animationPresets.moveToLeftScaleUp);
  };

  return (
    category && (
      <Link
        to="/kontaktai"
        className="phone"
        title="UŽSAKYMO INFORMACIJA"
        onMouseDown={onMouseDown}
      >
        <PhoneIcon />
      </Link>
    )
  );
};

export default Phone;
