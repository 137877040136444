import React, {
  forwardRef,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import { Link, useHistory } from "react-router-dom";
import { AppContext } from "../../AppContext";
import { animationPresets } from "../../AppContext";
import { ReactComponent as ArrowPrevSvg } from "../../assets/arrow-prev.svg";
import { ReactComponent as ArrowNextSvg } from "../../assets/arrow-next.svg";
import "./navigation.scss";

const useKeyPress = (targetKey) => {
  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  });

  return keyPressed;
};

const useIsInViewport = (ref) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      ),
    []
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
};

const EmptyLink = forwardRef((props, ref) => (
  <span ref={ref} className={props.className}>
    {props.children}
  </span>
));

export const ArrowPrev = ({ to = "", dark, anchor = true }) => {
  const { setPreset } = useContext(AppContext);
  const history = useHistory();
  const leftPress = useKeyPress("ArrowLeft");

  const linkRef = useRef(null);
  const isInViewport = useIsInViewport(linkRef);

  const onMouseDown = () => setPreset(animationPresets.moveToRightScaleUp);
  let props = { to };
  if (!anchor) {
    props = { ...props, component: EmptyLink };
  }

  useEffect(() => {
    if (leftPress && isInViewport) {
      setPreset(animationPresets.moveToRightScaleUp);
      setTimeout(() => history.push(to)); // not pretty, but who's easy now... If seriously, preset should be set a rendering cycle earlier than actual animation start.
    }
  }, [history, isInViewport, leftPress, setPreset, to]);

  return (
    <Link
      ref={linkRef}
      className={`arrow-prev ${!!dark ? "arrow-prev--dark" : ""}`}
      onMouseDown={onMouseDown}
      {...props}
    >
      <ArrowPrevSvg />
    </Link>
  );
};

export const ArrowNext = ({ to = "", dark, anchor = true }) => {
  const { setPreset } = useContext(AppContext);
  const history = useHistory();
  const rightPress = useKeyPress("ArrowRight");

  const linkRef = useRef(null);
  const isInViewport = useIsInViewport(linkRef);

  const onMouseDown = () => setPreset(animationPresets.moveToLeftScaleUp);

  let props = { to };
  if (!anchor) {
    props = { ...props, component: EmptyLink };
  }

  useEffect(() => {
    if (rightPress && isInViewport) {
      setPreset(animationPresets.moveToLeftScaleUp);
      setTimeout(() => history.push(to));
    }
  }, [history, isInViewport, rightPress, setPreset, to]);

  return (
    <Link
      ref={linkRef}
      className={`arrow-next ${!!dark ? "arrow-next--dark" : ""}`}
      onMouseDown={onMouseDown}
      {...props}
    >
      <ArrowNextSvg />
    </Link>
  );
};
