import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import ProductList from "../../components/ProductList";
import { ArrowPrev, ArrowNext } from "../Navigation";
import { ReactComponent as Logo } from "../../assets/2022/logo.svg";
import NoData from "../../components/NoData";
import {
  useGetProductsQuery,
  useGetCategoriesQuery,
} from "../../app/services/products";
import { setBrand, setCategory } from "../../features/path/pathSlice";

import Loader from "../Loader";
import "./page-category.scss";

const PageCategory = () => {
  const dispatch = useDispatch();
  const { category_url, group_id_param } = useParams();

  const { data: products, isLoading: isProductsLoading } = useGetProductsQuery({
    category_url,
  });
  const { data: categories, isLoading: isCatsLoading } =
    useGetCategoriesQuery();

  const category = categories?.find(
    (cat) => cat.url.toLowerCase() === category_url.toLowerCase()
  );

  const currentCategoryIndex = categories?.findIndex(
    (cat) => cat.url.toLowerCase() === category_url.toLowerCase()
  );

  const prev_category_url =
    categories?.[currentCategoryIndex - 1]?.url.toLowerCase();
  const next_category_url =
    categories?.[currentCategoryIndex + 1]?.url.toLowerCase();

  const productGroups = products?.reduce((acc, curr) => {
    acc[curr.group_id] = acc[curr.group_id]
      ? [...acc[curr.group_id], curr]
      : [curr];
    return acc;
  }, []);

  const productGroupsIds = products?.reduce((acc, curr) => {
    acc = [...acc, curr.group_id];
    return [...new Set(acc)];
  }, []);

  let currentGroupId = group_id_param || productGroupsIds?.[0];
  if (group_id_param === "last-group" && productGroupsIds) {
    currentGroupId = productGroupsIds[productGroupsIds.length - 1];
  }
  // assuming all currently displaying group products have the same submenu (named `brand` in DB)
  const firstProduct = products?.find(
    (product) => product.group_id === currentGroupId
  );
  const brand = firstProduct?.brand;

  useEffect(() => {
    category && dispatch(setCategory(category));
    dispatch(setBrand(brand));
  }, [category, brand, dispatch]);

  const nextGroupId = (currentGroupId) => {
    const currentIndex = productGroupsIds.findIndex(
      (el) => String(el) === String(currentGroupId)
    );

    return productGroupsIds[currentIndex + 1];
  };

  const prevGroupId = (currentGroupId) => {
    const currentIndex = productGroupsIds.findIndex(
      (el) => String(el) === String(currentGroupId)
    );

    return productGroupsIds[currentIndex - 1];
  };

  return (
    <div className="page-category">
      <div className="page-category__inner">
        <div className="page-category__header">
          <div className="page-category__logo-wrapper">
            <Link to="/katalogas" className="page-category__logo">
              <Logo className="page-category__logo" />
            </Link>
          </div>
        </div>

        <div className="page-category__list">
          {(isProductsLoading || isCatsLoading) && <Loader />}
          {!products && !isProductsLoading && <NoData />}
          {productGroupsIds &&
            productGroupsIds.map((currentGroupId, index) => (
              <div
                key={currentGroupId}
                className={`product-group ${
                  group_id_param === currentGroupId ||
                  (index === 0 && !group_id_param) ||
                  (index === productGroupsIds.length - 1 &&
                    group_id_param === "last-group")
                    ? "show"
                    : "hide"
                }`}
              >
                {prevGroupId(currentGroupId) ? (
                  <ArrowPrev
                    to={`/katalogas/${category_url}/${prevGroupId(
                      currentGroupId
                    )}`}
                    dark
                  />
                ) : prev_category_url ? (
                  <ArrowPrev
                    to={`/katalogas/${prev_category_url}/last-group`}
                    dark
                  />
                ) : (
                  <ArrowPrev to={`/katalogas`} dark />
                )}
                <ProductList
                  category_url={category_url}
                  products={productGroups[currentGroupId]}
                />
                {nextGroupId(currentGroupId) ? (
                  <ArrowNext
                    to={`/katalogas/${category_url}/${nextGroupId(
                      currentGroupId
                    )}`}
                    dark
                  />
                ) : (
                  next_category_url && (
                    <ArrowNext to={`/katalogas/${next_category_url}`} dark />
                  )
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default PageCategory;
