import React from "react";
import { ReactComponent as More } from "../../assets/arrow-more.svg";
import backgroundSizer from "../../assets/empty_1x1.png";
// import { baseUrl } from "../../app/constants";
import { productImageBaseUrl, productFullBaseUrl } from "../../app/constants";
import "./product-list.scss";

const ProductList = ({ category_url, products }) => {
  return (
    <div className="product-list">
      {products?.map((product, i) => {
        const url = `${productFullBaseUrl}/${product.url}`;

        const priceFormatted =
          product.price && `${Number(product.price).toFixed(2)} €`;

        const priceSpecialFormatted =
          product.special_price &&
          `${Number(product.special_price).toFixed(2)} €`;

        const price =
          category_url === "nealkoholiniai_gerimai" ||
          category_url === "gaivieji_gerimai"
            ? priceSpecialFormatted
              ? priceSpecialFormatted
              : priceFormatted
            : priceFormatted;

        const nameArr = product.name.split(/\((.+?)\)/g); // split name by taking content from "( )"
        return (
          <a
            key={i}
            className="product-item"
            href={url}
            alt={product.name}
            title={product.name}
            target="_blank"
            rel="noreferrer"
          >
            <div
              className="product-item__image-link"
              style={{
                backgroundImage: `url(${productImageBaseUrl}${product.image})`,
              }}
            >
              <img
                alt={product.name}
                title={product.name}
                src={backgroundSizer}
              />
              {product.new && <div className="product-item__new"></div>}
            </div>
            <div className="product-item__name">
              {nameArr[0]}
              {nameArr[1] && (
                <span className="product-item__subname">({nameArr[1]})</span>
              )}
            </div>
            <div className="product-item__attributes">
              {product.brand_origin
                ? product.brand_origin
                : product.origin && product.origin}
              {product.abv && // leave only numbers and ".," then convert to Number
                ` / ${Number(product.abv.replace(/[^\d.,]/g, ""))}%`}
            </div>
            <div className="product-item__price">
              {price}
              <span className="product-item__attributes">
                {product.volume && ` / ${product.volume}`}
              </span>
            </div>
            <div className="product-item__depozitas">
              {product.depozitas && `+ 0,10 € užstato mokestis`}
            </div>
            <div className="product-item__attributes">
              Plačiau <More />
            </div>

            <div className="product-item__overlay"></div>
          </a>
        );
      })}
    </div>
  );
};

export default ProductList;
